<template>
	<div class="default-layout shadow-2">
		<div class="view-header shadow-2">
			<div class="upper-section shadow-2">
				<div class="logo-container">
					<q-img
						src="/images/neobanx_logo_simple.svg" 
						:ratio="414/95"
						position="0 0"
						contain
					/>
				</div>

				<div class="buttons-container shadow-2">
					<a class="sign-up">
						Sign Up
					</a>

					<a class="log-in">
						Log In
					</a>

					<a class="main-menu" @click="methods.toggleMainMenu(!state.showMainMenu)">
						<q-icon name="menu" />
					</a>
				</div>
			</div>

			<div class="lower-section">
				<div class="left-nav">
					<a>Lending</a> 

					<q-icon name="circle" />

					<a>Borrowing</a> 
					
					<q-icon name="circle" />
					
					<a>Community</a>
					
					<q-icon name="circle" />
					
					<a>My Neobanx</a> 
					
					<q-icon name="circle" />
					
					<a class="highlighted">Invite</a>
				</div>

				<div class="right-nav">
					<a>
						<q-icon name="home" />
						Home
					</a>

					<a>
						<q-icon name="info" />
						Help
					</a>
				</div>
			</div>
		</div>

		<div class="view-content">
			<router-view class="full-container" />

			<div :class="'main-menu-container shadow-4' + (state.showMainMenu ? '' : ' hide')">
				<div class="main-menu">
					<a class="menu-item" style="color: #EBB934;">Sign Up</a>

					<a class="menu-item">Log In</a>
					
					<div class="menu-separator">
						<hr />
					</div>
					
					<a class="menu-item">Home</a>
					
					<a class="menu-item">Help</a>
					
					<div class="menu-separator">
						<hr />
					</div>
					
					<a class="menu-item">Lending</a>
					
					<a class="menu-item">Borrowing</a>
					
					<a class="menu-item">Community</a>
					
					<a class="menu-item">My Neobanx</a>
					
					<a class="menu-item" style="color: #EBB934;">Invite</a>

					<div class="menu-separator">
						<hr />
					</div>
					
					<a class="menu-item">About Neobanx</a>
					
					<a class="menu-item">Contact Us</a>
					
					<a class="menu-item">Terms Of Use</a>
					
					<a class="menu-item">Privacy Policy</a>
				</div>
			</div>

			<div :class="'modal-blocker' + (state.showModalContainer ? '' : ' hide')" />
		</div>

		<div class="view-footer">
			<div class="nav">
				<a>About Neobanx</a>  

				<q-icon name="circle" />

				<a>Contact Us</a> 

				<q-icon name="circle" />

				<a>Terms Of Use</a> 

				<q-icon name="circle" />

				<a>Privacy Policy</a>
			</div>
			<div class="copyright-container">
				<span class="copyright-text">
					Copyright &copy; 2021 Neobanx Technologies Inc.
				</span>
				<span class="copyright-text">
					All Rights Reserved.
				</span>
			</div>
		</div>

		<q-resize-observer @resize="methods.onViewResized()" />
	</div>
</template>

<script>
import { defineComponent, reactive } from '@vue/composition-api'

export default defineComponent({
	name: 'DefaultLayout',

	setup() {
		const state = reactive({
			showRightDrawer: true,
			showModalContainer: false,
			showMainMenu: false
		})

		const methods = {
			toggleMainMenu: (show) => {
				state.showMainMenu = show

				if (!show) {
					setTimeout(() => {
						state.showModalContainer = show
					}, 500)
				}
				else {
					state.showModalContainer = show
				}
			},

			onViewResized: () => {
				if (window.innerWidth > 920) {
					methods.toggleMainMenu(false)
				}
			}
		}

		return { state, methods }
	}
})
</script>

<style lang="scss" scoped>
.default-layout {
	display: flex;
	flex-direction: column;
	width: calc(100% - 16px);
	height: calc(100% - 16px);
	background-color: #ddd;
	border-radius: 8px;
	overflow: hidden;
	border: 1px solid rgba($color: #fff, $alpha: 0.2);
	position: relative;

	.view-header {
		display: flex;
		flex-direction: column;
		width: 100%;
		z-index: 2;
		border-bottom: 1px solid rgba($color: #fff, $alpha: 0.2);

		.upper-section {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			height: 72px;
			//border: 1px solid #00f;
			padding: 0 16px;
			z-index: 1;

			.logo-container {
				display: flex;
				width: 215px;
				height: 50px;
				//border: 1px solid #f00;
			}

			.buttons-container {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 1px;
				background-color: rgba($color: #fff, $alpha: 0.2);
				border-radius: 8px;
				border: 1px solid rgba($color: #fff, $alpha: 0.2);

				@media only screen and (max-width: 920px) {
					grid-template-columns: 1fr;
				}

				a {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100px;
					height: 36px;
					color: rgba($color: #fff, $alpha: 0.7);
					//font-weight: 500;
					cursor: pointer;
				}

				a:hover {
					filter: brightness(110%);
				}

				.sign-up {
					border-radius: 8px 0 0 8px; 
					background-color: #EA7915;

					@media only screen and (max-width: 920px) {
						display: none;
					}
				}

				.log-in {
					border-radius: 0 8px 8px 0; 
					background-color: #5761A7;

					@media only screen and (max-width: 920px) {
						display: none;
					}
				}

				.main-menu {
					display: none;
					font-size: 24px;
					border-radius: 8px; 
					background-color: #5761A7;
					width: 40px;

					@media only screen and (max-width: 920px) {
						display: flex;
					}
				}
			}
		}

		.lower-section {
			display: grid;
			grid-template-columns: 1fr 200px; 
			width: 100%; 
			height: 28px; 
			background-color: #5761A7; 
			justify-content: center; 
			align-items: center; 
			color: rgba($color: #fff, $alpha: 0.7);
			padding: 0 16px;

			@media only screen and (max-width: 920px) {		 
				height: 12px; 
			}
			
			.left-nav {
				display: flex;
				align-items: center;

				@media only screen and (max-width: 920px) {
					display: none;
				}

				a {
					font-size: 13px;
					font-weight: bolder;
					text-transform: uppercase;
					cursor: pointer;
				}

				a.highlighted {
					display: flex;
					background-color: #EBB934;
					border-radius: 4px;
					padding: 0 16px;
					color: #EA7915;
				}

				a:hover {
					//filter: brightness(90%);
					color: rgba($color: #fff, $alpha: 0.9);
				}

				.q-icon {
					display: inline-block;
					font-size: 6px;
					margin: 0 8px;
					color: #EA7915;
				}
			}

			.right-nav {
				display: grid;
				grid-template-columns: 1fr 1fr;
				width: 200px;
				gap: 4px;

				@media only screen and (max-width: 920px) {
					display: none;
				}

				a {
					display: flex;
					justify-content: center;
					align-items: center;
					text-transform: uppercase;
					font-size: 13px;
					font-weight: bolder;
					cursor: pointer;

					.q-icon {
						font-size: 18px;
						margin-right: 4px;
					}
				}

				a:hover {
					color: rgba($color: #fff, $alpha: 0.9);
				}
			}
		}
	}

	.view-content {
		display: flex;
		flex: 1;
		overflow: hidden;
		position: relative;

		.main-menu-container {
			display: flex;
			flex-direction: column;
			position: absolute;
			top: 0;
			right: 0;
			padding: 20px;
			backdrop-filter: blur(5px);
			background: linear-gradient(90deg, rgba(#5761A7, 0.95) 0, rgba(#30355C, 0.95) 100%); 
			border-left: 1px solid rgba(#fff, 0.5);
			height: 100%;
			overflow: auto;
			transform-origin: top right;
			transform: translateX(0);
			transition: transform 0.3s ease-out;
			z-index: 25;

			.main-menu {
				display: flex;
				flex-direction: column;
				min-width: 180px;
				font-size: 14px;
				font-weight: 500;
				text-transform: uppercase;
				
				.menu-item {
					display: flex;
					padding: 2px 10px !important;
					white-space: nowrap;
					margin-top: 4px;
					border-radius: 4px;
					cursor: pointer;
					background-color: transparent;
					color: rgba($color: #fff, $alpha: 0.7);
				}

				.menu-item:first-child {
					margin-top: 0;
				}

				.menu-item:hover {
					background-color: rgba(#000, 0.1);
					color: rgba($color: #fff, $alpha: 0.9);
				}

				.menu-separator {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 24px;

					hr {
						width: 100%;
						border-top: none;
						border-left: none;
						border-right: none;
						border-bottom: 1px solid rgba(#fff, 0.1);
					}
				}
			}
		}

		.main-menu-container.hide {
			transform: translateX(100%);
			transition: transform 0.3s ease-in;
		}

		.modal-blocker {
			display: flex;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(#000, 0.1);
			z-index: 20;
		}

		.modal-blocker.hide {
			visibility: hidden;
		}
	}

	.view-footer {
		display: flex;
		justify-content: space-between;
		height: 25px;
		width: 100%;
		//border: 1px solid #f00;
		background-color: #5761A7;
		border-top: 1px solid rgba($color: #fff, $alpha: 0.7);
		z-index: 2;
		padding: 0 16px;

		@media only screen and (max-width: 920px) {
			justify-content: center;
			height: fit-content;
		}

		.nav {
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #5761A7;
			//border: 1px solid #f00;

			@media only screen and (max-width: 920px) {
				display: none;	
			}

			a {
				font-size: 13px;
				text-transform: uppercase;
				cursor: pointer;
				font-weight: bolder;
				color: rgba($color: #fff, $alpha: 0.7);
			}

			a:hover {
				color: rgba($color: #fff, $alpha: 0.9);
			}

			.q-icon {
				display: inline-block;
				font-size: 6px;
				margin: 0 8px;
				color: #EA7915;
			}
		}

		.copyright-container {
			justify-content: center;
			align-items: center;
			background-color: #5761A7;
			text-align: center;
			line-height: 12px;
			padding: 4px;
			
			.copyright-text {
				display: inline-block;
				font-size: 10px;
				letter-spacing: 1px;
				color: rgba($color: #fff, $alpha: 0.7);
			}
		}
	}
}
</style>